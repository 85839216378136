import React, { useState } from "react"
import Img from "gatsby-image"
import tw, { styled } from "twin.macro"
import Layout from "../components/layout"
// import { CartItems } from "../components/cart"
import Footer from "../components/Footer"
import LogRocket from "logrocket"

import { Link } from "gatsby"
import {
  HeaderWrap,
  SubHeader,
  Header,
  ProductWrap,
  ProductCard,
} from "../components/productListStyles"
import { FiShoppingCart } from "react-icons/fi"
import { CouponBanner } from "."
LogRocket.init("ziiqiw/studio-conscious")

LogRocket.init("ziiqiw/studio-conscious")
const SoonCard = styled.div`
  height: 550px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ background }) => background});
  display: grid;
  align-items: center;
  justify-items: center;
  h3 {
    margin: 0;
    a {
      font-size: 40px;
      font-family: Playfair Display;
      color: ${({ colorSelect }) => colorSelect};
    }
  }
`

const HeaderGroup = styled.div`
  font-family: "Playfair Display";
  font-weight: 600;
  font-size: 42px;
  text-align: center;
  text-transform: lowercase;
  @media (max-width: 1024px) {
    font-size: 26px;
  }
`

const ScrollTo = styled.div`
  height: 75px;
`

// const CartButton = styled.div`
//   position: fixed;
//   top: 50px;
//   right: 15px;
//   @media (min-width: 1024px) {
//     top: 15px;
//     right: 30px;
//   }
//   z-index: 6000;
//   button {
//     &:hover {
//       cursor: pointer;
//     }
//     background: none;
//     border: none;
//     &:focus {
//       outline: none;
//     }
//   }
// `

const SoonWrapper = styled.div`
  ${tw`grid grid-cols-1 col-gap-16 sm:grid-cols-3 mt-8 mb-16`}
`

const Curations = ({ data, location }) => {
  // const [close, setClose] = useState(false)

  // const toggleCart = () => {
  //   setClose(!close)
  // }
  // const showCart = () => {
  //   setClose(true)
  // }

  return (
    <Layout location={location}>
      {/* <CartItems close={close} location={location} />
      <CartButton>
        <button onClick={toggleCart}>
          <FiShoppingCart size={25} />
        </button>
      </CartButton> */}{" "}
      {/* <CouponBanner>
        <h2>25% off all products </h2>
        <h3>automatically applied at checkout</h3>
      </CouponBanner> */}
      <HeaderWrap>
        {data.sanityCurations.header.map((head, i) => {
          return (
            <Header key={i} color={head.myColor.hex}>
              {head.title}
            </Header>
          )
        })}
      </HeaderWrap>
      <SubHeader>{data.sanityCurations.subHeader}</SubHeader>
      <ScrollTo id="shelves"></ScrollTo>
      <HeaderGroup>Shelves</HeaderGroup>
      <ProductWrap>
        {data.shelves.edges.map(({ node: product }, i) => {
          return (
            <Link
              key={i}
              to={`/curations/${product.name
                .replace(/\s/g, "-")
                .toLowerCase()}/${product.price_id}`}
            >
              <ProductCard>
                <Img
                  fluid={
                    product.featureImage && product.featureImage.asset.fluid
                  }
                />
                <h3>{product.name}</h3>
              </ProductCard>
            </Link>
          )
        })}
      </ProductWrap>
      <ScrollTo id="coffeetables"></ScrollTo>
      <HeaderGroup>Coffee Tables</HeaderGroup>
      <ProductWrap>
        {data.coffeeTables.edges.map(({ node: product }, i) => {
          return (
            <Link
              key={i}
              to={`/curations/${product.name
                .replace(/\s/g, "-")
                .toLowerCase()}/${product.price_id}`}
            >
              <ProductCard>
                <Img
                  fluid={
                    product.featureImage && product.featureImage.asset.fluid
                  }
                />
                <h3>{product.name}</h3>
              </ProductCard>
            </Link>
          )
        })}
      </ProductWrap>
      <ScrollTo id="tables"></ScrollTo>
      <HeaderGroup>Tables</HeaderGroup>
      <ProductWrap>
        {data.tables.edges.map(({ node: product }, i) => {
          return (
            <Link
              key={i}
              to={`/curations/${product.name
                .replace(/\s/g, "-")
                .toLowerCase()}/${product.price_id}`}
            >
              <ProductCard>
                <Img
                  fluid={
                    product.featureImage && product.featureImage.asset.fluid
                  }
                />
                <h3>{product.name}</h3>
              </ProductCard>
            </Link>
          )
        })}
      </ProductWrap>
      <ScrollTo id="speciality"></ScrollTo>
      <HeaderGroup>Speciality</HeaderGroup>
      <ProductWrap>
        {data.speciality.edges.map(({ node: product }, i) => {
          return (
            <Link
              key={i}
              to={`/curations/${product.name
                .replace(/\s/g, "-")
                .toLowerCase()}/${product.price_id}`}
            >
              <ProductCard>
                <Img
                  fluid={
                    product.featureImage && product.featureImage.asset.fluid
                  }
                />
                <h3>{product.name}</h3>
              </ProductCard>
            </Link>
          )
        })}
      </ProductWrap>
      <HeaderWrap>
        <Header
          className="spin"
          color={data.sanityCurations.comingSoonColor.hex}
        >
          {data.sanityCurations.comingSoonHeader}
        </Header>
      </HeaderWrap>
      <SoonWrapper>
        {data.allSanityComingSoon.edges.map(({ node: soon }, i) => {
          return (
            <SoonCard
              colorSelect={soon.titleColor.hex}
              background={soon.background.asset.url}
            >
              <h3>
                <a href={soon.link}>{soon.title}</a>
              </h3>
            </SoonCard>
          )
        })}
      </SoonWrapper>
      <Footer />
    </Layout>
  )
}

export default Curations

export const query = graphql`
  query ProductsQuery {
    shelves: allSanityProducts(
      sort: { order: ASC, fields: order }
      filter: {
        categories: { title: { eq: "Shelves" } }
        archived: { eq: false }
        page: { eq: "curations" }
      }
    ) {
      edges {
        node {
          order
          categories {
            title
          }
          price_id
          product_id
          price
          name
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    tables: allSanityProducts(
      sort: { order: ASC, fields: order }
      filter: {
        categories: { title: { eq: "Tables" } }
        archived: { eq: false }
        page: { eq: "curations" }
      }
    ) {
      edges {
        node {
          order
          categories {
            title
          }
          price_id
          product_id
          price
          name
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    coffeeTables: allSanityProducts(
      sort: { order: ASC, fields: order }
      filter: {
        categories: { title: { eq: "Coffee Tables" } }
        archived: { eq: false }
        page: { eq: "curations" }
      }
    ) {
      edges {
        node {
          order
          categories {
            title
          }
          price_id
          product_id
          price
          name
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    speciality: allSanityProducts(
      sort: { order: ASC, fields: order }
      filter: {
        categories: { title: { eq: "Speciality" } }
        archived: { eq: false }
        page: { eq: "curations" }
      }
    ) {
      edges {
        node {
          order
          categories {
            title
          }
          price_id
          product_id
          price
          name
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    sanityCurations {
      comingSoonHeader
      comingSoonColor {
        hex
      }
      header {
        title
        myColor {
          hex
        }
      }
      subHeader
    }
    allSanityComingSoon(filter: { page: { eq: "curations" } }) {
      edges {
        node {
          background {
            asset {
              url
            }
          }
          title
          link
          titleColor {
            hex
          }
        }
      }
    }
  }
`
